<template>
    <div id="app" :data-scroll="burgerNavIsOpen ? 'prevent-scroll' : ''">
        <!-- Nav Components -->
        <NavBar class="force-zindex-on-mobile" />
        <NavBar class="is-sticky" :isSticky="true" />
        <BurgerNav :class="{ 'is-active': burgerNavIsOpen }" />

        <!-- Main content -->
        <div class="main-wrapper" :class="{ fluid: $route.meta.layout === 'fluid' }">
            <transition name="page" mode="out-in">
                <router-view />
            </transition>
        </div>

        <!-- Footer -->
        <Footer />

        <!-- Performa -->
        <Performa />

        <!-- Site Loader -->
        <SiteLoader />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import BurgerNav from '@/components/BurgerNav'
import Footer from '@/components/Footer'
import Performa from '@/components/Performa'
import SiteLoader from '@/components/SiteLoader'

import { initPrerenderLinks } from '@/plugins/PrerenderLinks'

export default {
    computed: {
         pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['burgerNavIsOpen', 'contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    components: {
        NavBar,
        BurgerNav,
        Footer,
        Performa,
        SiteLoader,
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initPrerenderLinks()
                    }, 1)

                    if (window.location.href.includes('/web')) {
                        // Remove the /web from the URL
                        window.location.href = window.location.href.replace('/web', '')
                    }
                }
            }
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.page-wrapper.is-loaded {
    &:before {
        content: '';
        position: fixed;
        top: 0;
        right: -100vw;
        bottom: 0;
        left: 0;
        background-color: #000;
        z-index: 999999;

        transform: translate3d(100%, 0, 0);

        transition-duration: 2s;
        transition-delay: 0.245s;
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
    }
}

.page-enter-active,
.page-leave-active {
    transition-duration: 2.1s;
    transition-property: opacity, transform;
    // transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);

    .hero,
    .hero-transition-group {
        transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s,
            transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
    }

    .projet {
        transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s,
            transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
    }
}

.page-enter,
.page-leave-active {
    .hero,
    .hero-transition-group {
        transform: translate3d(-45px, 0, 0);
        opacity: 0;
    }

    .projet {
        transform: translate3d(-45px, 0, 0);
        opacity: 0;
    }

    &.is-loaded:before {
        transform: translate3d(-200vw, 0, 0);
    }
}
</style>
