<template>
    <a href="https://performa-marketing.com/" target="_blank" class="performa-wrap">
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/credits-performa-fr.svg" alt="Performa">
        <img v-if="$route.meta.lang === 'en'" src="@/assets/img/credits-performa-en.svg" alt="Performa">
    </a>
</template>

<script>
    export default {
        name: 'Performa',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .performa-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: 25px;

        img {
            width: auto;
            height: 18px;
        }
    }
</style>
