import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/le-projet',
        name: 'Projet-fr',
        component: () => import(/* webpackChunkName: "projet" */ '../views/Project.vue'),
        meta: { lang: 'fr', json: 'leProjet' },
    },
    {
        path: '/en/the-project',
        name: 'Projet-en',
        component: () => import(/* webpackChunkName: "projet" */ '../views/Project.vue'),
        meta: { lang: 'en', json: 'leProjet' },
    },
    {
        path: '/condos',
        name: 'Condos-fr',
        component: () => import(/* webpackChunkName: "condos" */ '../views/Condos.vue'),
        meta: { lang: 'fr', json: 'lesCondos' },
    },
    {
        path: '/en/condos',
        name: 'Condos-en',
        component: () => import(/* webpackChunkName: "condos" */ '../views/Condos.vue'),
        meta: { lang: 'en', json: 'lesCondos' },
    },
    {
        path: '/quartier',
        name: 'Quartier-fr',
        component: () => import(/* webpackChunkName: "quartier" */ '../views/Quartier.vue'),
        meta: { lang: 'fr', json: 'leQuartier' },
    },
    {
        path: '/en/quartier',
        name: 'Quartier-en',
        component: () => import(/* webpackChunkName: "quartier" */ '../views/Quartier.vue'),
        meta: { lang: 'en', json: 'leQuartier' },
    },
    {
        path: '/contact',
        name: 'Contact-fr',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'fr', json: 'contact', layout: 'fluid' },
    },
    {
        path: '/en/contact',
        name: 'Contact-en',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'en', json: 'contact', layout: 'fluid' },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'en', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    hashbag: true,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    x: 0,
                    y: 0,
                    // behavior: 'smooth',
                })
            }, 950)
        })
    }
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')

        let type = 'pages'
        // const { meta, params } = to
        const { meta } = to
        let slug = meta.json

        Store.dispatch('getContent', { lang: meta.lang, slug, type })

    }, 425)

    next()
})

export default router
