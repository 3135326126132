<template>
    <nav id="burger-nav" class="burger-nav">
        <div class="nav-bar-wrap" @click.prevent.stop="toggleBurgerNav(!burgerNavIsOpen)">
            <NavBar :child="true" />
        </div>
        <div class="inner site-max-width-large">
            <div class="nav-links" @click.prevent.stop="toggleBurgerNav(!burgerNavIsOpen)">
                <router-link
                    :to="{ name: 'Contact-' + $route.meta.lang }"
                    class="nav-link big-title mobile-link"
                    :title="$t('Inscrivez-vous')"
                    data-prerender-link
                >
                    <span>{{ $t('Inscrivez-vous') }}</span>
                    <div class="img-wrap">
                        <img src="@/assets/img/lomboi-menu-projet.jpg" alt="Interior" />
                    </div>
                </router-link>

                <router-link
                    :to="{ name: item.slug + '-' + $route.meta.lang }"
                    class="nav-link big-title"
                    :title="item.titre"
                    data-prerender-link
                    v-for="(item, i) in navLinks" :key="i"
                >
                    <span>{{ item.titre }}</span>
                    <div class="img-wrap">
                        <img :src="item.image.url" :alt="item.image.titre" />
                    </div>
                </router-link>

                <router-link
                    :to="{ name: 'Contact-' + $route.meta.lang }"
                    class="nav-link big-title mobile-link"
                    :title="$t('Contactez-nous')"
                    data-prerender-link
                >
                    <span>{{ $t('Contactez-nous') }}</span>
                    <div class="img-wrap">
                        <img src="@/assets/img/lomboi-menu-projet.jpg" alt="Interior" />
                    </div>
                </router-link>
            </div>
            <footer class="burger-footer" @click.prevent.stop="toggleBurgerNav(!burgerNavIsOpen)">
                <router-link
                    :to="{ name: 'Contact-' + $route.meta.lang }"
                    class="inline-link"
                    :title="$t('Contactez-nous')"
                    data-prerender-link
                >
                    <span>{{ $t('Contactez-nous') }}</span>
                    <svg
                        height="12"
                        viewBox="0 0 26 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.8637 1.55923L23.426 8.02694L1 8.02694L0.850004 8.02694L0.850004 8.17694L0.850004 8.82306L0.850004 8.97306L1 8.97306L23.4273 8.97306L16.8635 15.4732L16.7518 15.5838L16.8675 15.6902L17.3243 16.1104L17.4297 16.2073L17.5314 16.1066L25.1055 8.60659L25.2132 8.5L25.1055 8.39341L17.5314 0.893413L17.4259 0.788903L17.3203 0.893413L16.8635 1.34581L16.7556 1.45264L16.8637 1.55923Z"
                            fill="black"
                            stroke="black"
                            stroke-width="0.3"
                        />
                    </svg>
                </router-link>
                <a href="http://www.devauban.com/" target="_blank" rel="noopener noreferrer" @click.prevent.stop="goToLink('http://www.devauban.com/')">
                    <Devauban color="#000" />
                </a>
            </footer>
        </div>
    </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import Devauban from '@/components/svgs/Devauban'

export default {
    name: 'BurgerNav',

    components: {
        NavBar,
        Devauban,
    },

    computed: {
        navLinks() {
            if (!this.globalsIsLoaded) {
                return []
            }

            const slugs = [
                'Projet',
                'Condos',
                'Quartier',
            ]
            return this.globals.mainNav.grilleDeNavigation.map((item, i) => {
                return {
                    titre: item.titre,
                    image: item.image,
                    slug: slugs[i]
                }
            })
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['burgerNavIsOpen', 'contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['globals']),
    },

    methods: {
        goToLink(url) {
            window.open(url, '_blank')
        },
        ...mapActions(['toggleBurgerNav']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
