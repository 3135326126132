export const initPrerenderLinks = () => {
    // PreRender for fast website
    function prerenderlink(e) {
        // console.log(e, e.currentTarget, e.currentTarget.href)
        const head = document.getElementsByTagName('head')[0]
        const refs = head.childNodes
        const ref = refs[refs.length - 1]

        var elements = head.getElementsByTagName('link')
        Array.prototype.forEach.call(elements, function (el) {
            if ('rel' in el && el.rel === 'prerender') {
                el.parentNode.removeChild(el)
            }
        })

        var prerenderTag = document.createElement('link')
        prerenderTag.rel = 'prerender'
        prerenderTag.href = e.currentTarget.href
        ref.parentNode.insertBefore(prerenderTag, ref)
    }

    const $prerenderLinks = document.querySelectorAll('[data-prerender-link]')
    ;[].slice.call($prerenderLinks).forEach(function (elem) {
        elem.addEventListener('mouseenter', prerenderlink)
    })
}
