<template>
    <footer class="footer site-max-width-large">
        <div class="inner" data-inview="fadeInUp" data-delay="400">
            <nav class="footer-nav">
                <router-link :to="{ name: 'Projet-' + $route.meta.lang }" class="nav-link"><span>{{ $t('Le projet') }}</span></router-link>
                <router-link :to="{ name: 'Condos-' + $route.meta.lang }" class="nav-link"><span>{{ $t('Les condos') }}</span></router-link>
                <router-link :to="{ name: 'Quartier-' + $route.meta.lang }" class="nav-link"
                    ><span>{{ $t('Le quartier') }}</span></router-link
                >
                <router-link :to="{ name: 'Contact-' + $route.meta.lang }" class="nav-link"><span>{{ $t('Contact') }}</span></router-link>
            </nav>

            <a href="http://www.devauban.com/" target="_blank" rel="noopener noreferrer" class="logo">
                <Devauban color="#fff" />
            </a>
        </div>
    </footer>
</template>

<script>
import Devauban from '@/components/svgs/Devauban'

export default {
    name: 'Footer',

    components: {
        Devauban,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
