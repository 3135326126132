import { render, staticRenderFns } from "./Performa.vue?vue&type=template&id=500163de&scoped=true&"
import script from "./Performa.vue?vue&type=script&lang=js&"
export * from "./Performa.vue?vue&type=script&lang=js&"
import style0 from "./Performa.vue?vue&type=style&index=0&id=500163de&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "500163de",
  null
  
)

export default component.exports